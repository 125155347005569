.pac-container {
  margin-top: 8px;
  border: 1px solid #4870FF;
  border-radius: 8px;
  padding: 10px 20px;
}

.pac-container:after {
  display: none;
}

.pac-item {
  border: none;
  font-family: Montserrat, sans-serif;
  padding: 10px 0;
}

.pac-item:hover {
  background: none;
}

.pac-item:hover * {
  color: #4870FF;
}
